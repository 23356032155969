import React, { useEffect } from "react";
import Layout from "./layout.js";
import Fancybox from "../js/fancybox.js";
import infraImage1 from "../images/main-slider-img.png";
import infraImage2 from "../images/image9.png";
import infraImage3 from "../images/image4.png";
import infraImage4 from "../images/image11.png";
import infraImage5 from "../images/image12.png";
import infraImage6 from "../images/infraimage6.png";
import infraImage7 from "../images/infraimage7.png";
import infraImage8 from "../images/infraimage8.png";
import infraImage9 from "../images/infraimage9.png";
import infraImage10 from "../images/infraimage10.png";
import infraImage11 from "../images/infraimage11.png";
import infraImage12 from "../images/infraimage12.png";
import fancyboxInfraImage12 from "../images/infrastructure-fancybox/fancybox-infraimage12.jpg";
import fancyboxInfraImage11 from "../images/infrastructure-fancybox/fancybox-infraimage11.jpg";
import fancyboxInfraImage10 from "../images/infrastructure-fancybox/fancybox-infraimage10.jpg";
import fancyboxInfraImage9 from "../images/infrastructure-fancybox/fancybox-infraimage9.jpg";
import fancyboxInfraImage8 from "../images/infrastructure-fancybox/fancybox-infraimage8.jpg";
import fancyboxInfraImage7 from "../images/infrastructure-fancybox/fancybox-infraimage7.jpg";
import fancyboxInfraImage6 from "../images/infrastructure-fancybox/fancybox-infraimage6.jpg";
import fancyboxInfraImage5 from "../images/infrastructure-fancybox/fancybox-infraimage5.jpg";
import fancyboxInfraImage4 from "../images/infrastructure-fancybox/fancybox-infraimage4.jpg";
import fancyboxInfraImage3 from "../images/infrastructure-fancybox/fancybox-infraimage3.jpg";
import fancyboxInfraImage2 from "../images/infrastructure-fancybox/fancybox-infraimage2.jpg";
import { seo } from "../seo.js";

export default function Infrastructure({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    seo({
      title: "Infrastructure",
    });
  }, []);

  return (
    <Layout>
      <div className="common-pageheading">
        <div className="container">
          <h1 className="heading">Infrastructure</h1>
        </div>
      </div>

      <div className="infrastructure-page-sec">
        <div className="container">
          <div className="txt-div">
            <p className="txt">
              We make use all the available resources in an efficient manner in
              order to minimize the production cost. Owing to our transparent
              business dealings and timely delivery of orders, we have been able
              to gain the trust of our honourable customers.
            </p>
            <p className="txt">
              We strive to be a supplier of high temperature industrial ceramics
              products, with well organized new product development and
              excellent service rendering.
            </p>
          </div>

          <Fancybox
            options={{
              Carousel: {
                infinite: false,
              },
            }}
          >
            <div className="row infrastructure-page-sec-row">
              <div className="col-lg-4 col-md-6 infra-col">
                <div className="img-div">
                  <a data-fancybox="gallery" href={infraImage1}>
                    {" "}
                    <img
                      src={infraImage1}
                      alt="Infrastructure Images"
                      className="img-fluid infra-img"
                    ></img>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 infra-col">
                <div className="img-div">
                  <a data-fancybox="gallery" href={fancyboxInfraImage2}>
                    <img
                      src={infraImage2}
                      alt="Infrastructure Images"
                      className="img-fluid infra-img"
                    ></img>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 infra-col">
                <div className="img-div">
                  <a data-fancybox="gallery" href={fancyboxInfraImage3}>
                    <img
                      src={infraImage3}
                      alt="Infrastructure Images"
                      className="img-fluid infra-img"
                    ></img>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 infra-col">
                <div className="img-div">
                  <a data-fancybox="gallery" href={fancyboxInfraImage4}>
                    <img
                      src={infraImage4}
                      alt="Infrastructure Images"
                      className="img-fluid infra-img"
                    ></img>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 infra-col">
                <div className="img-div">
                  <a data-fancybox="gallery" href={fancyboxInfraImage5}>
                    <img
                      src={infraImage5}
                      alt="Infrastructure Images"
                      className="img-fluid infra-img"
                    ></img>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 infra-col">
                <div className="img-div">
                  <a data-fancybox="gallery" href={fancyboxInfraImage6}>
                    <img
                      src={infraImage6}
                      alt="Infrastructure Images"
                      className="img-fluid infra-img"
                    ></img>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 infra-col">
                <div className="img-div">
                  <a data-fancybox="gallery" href={fancyboxInfraImage7}>
                    <img
                      src={infraImage7}
                      alt="Infrastructure Images"
                      className="img-fluid infra-img"
                    ></img>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 infra-col">
                <div className="img-div">
                  <a data-fancybox="gallery" href={fancyboxInfraImage8}>
                    <img
                      src={infraImage8}
                      alt="Infrastructure Images"
                      className="img-fluid infra-img"
                    ></img>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 infra-col">
                <div className="img-div">
                  <a data-fancybox="gallery" href={fancyboxInfraImage9}>
                    <img
                      src={infraImage9}
                      alt="Infrastructure Images"
                      className="img-fluid infra-img"
                    ></img>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 infra-col">
                <div className="img-div">
                  <a data-fancybox="gallery" href={fancyboxInfraImage10}>
                    <img
                      src={infraImage10}
                      alt="Infrastructure Images"
                      className="img-fluid infra-img"
                    ></img>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 infra-col">
                <div className="img-div">
                  <a data-fancybox="gallery" href={fancyboxInfraImage11}>
                    <img
                      src={infraImage11}
                      alt="Infrastructure Images"
                      className="img-fluid infra-img"
                    ></img>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 infra-col">
                <div className="img-div">
                  <a data-fancybox="gallery" href={fancyboxInfraImage12}>
                    <img
                      src={infraImage12}
                      alt="Infrastructure Images"
                      className="img-fluid infra-img"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </Fancybox>
        </div>
      </div>
    </Layout>
  );
}
