import React, { useEffect, useState } from "react";
import Layout from "./layout.js";
import $ from "jquery";
import { useForm } from "react-hook-form";
import ReactDOM from "react-dom";
import { seo } from "../seo.js";
import axios from "axios";
import * as Constants from "../constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ContactUs({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    seo({
      title: "Contact Us",
    });
  }, []);

  useEffect(() => {
    document.querySelector("input[type='tel']").setAttribute("maxlength", "15");
  }, []);

  const client = axios.create({
    baseURL: Constants.BACKEND_API_URL,
  });

  const [inputMobileValue, setInputMobileValue] = useState("");

  const handleMobileInputChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^ +\d]/g, "");
    setInputMobileValue(numericValue);
  };

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
	 if (isLoading) {
      document.body.classList.add("loader-showing");
    } else {
      document.body.classList.remove("loader-showing");
    }
  }, [isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    setIsLoading(true);
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    };
    client.post("send-mail.php", data, { headers: headers,})
      .then((response) => {
        // Handle the response from the server if needed
    	setIsLoading(false);

        if (response.data.status_code == 1) {
          toast.success(response.data.message);
          reset();
          setInputMobileValue("");
          //document.getElementById("contact-us-form").reset();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // Handle errors if the request fails
        toast.error(error);
      });
  };

  return (
    <Layout>
      <div className="common-pageheading">
        <div className="container">
          <h4 className="heading">Contact Us</h4>
        </div>
      </div>
      {isLoading && (
        <div className="loader">
          <div className="loader--ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <>
        <section className="address-form">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="address-box">
                  <div className="add-title">
                    <h4>Address</h4>
                  </div>
                  <div className="add-list-wrap">
                    <div className="add-list add d-flex align-items-center">
                      <div className="icon">
                        <i className="fi fi-rs-marker contact-icons"></i>
                      </div>
                      <div className="text">
                        Sr.No. 85/A-2, Plot No:6, Old Rafaleshvar Road, Near
                        Millennium paper mill, At:Lalpar, Tal.Dist: Morbi. -
                        363642. Gujarat. INDIA
                      </div>
                    </div>
                    <div className="add-list mail d-flex align-items-center">
                      <div className="icon">
                        <i className="fi fi-rr-envelope-open contact-icons"></i>
                      </div>
                      <div className="text">
                        <a href="mailto:pavandimension@gmail.com">
                          pavandimension@gmail.com
                        </a>
                      </div>
                    </div>
                    <div className="add-list call d-flex align-items-center">
                      <div className="icon">
                        <i className="fi fi-rr-mobile-button contact-icons"></i>
                      </div>
                      <div className="text">
                        <h5 className="contact-person-name">
                          Shaileshbhai Dalsaniya
                        </h5>
                        <a href="tel:+919427121591">+91 94271 21591</a>
                      </div>
                    </div>
                    <div className="add-list call d-flex align-items-center">
                      <div className="icon">
                        <i className="fi fi-rr-mobile-button contact-icons"></i>
                      </div>
                      <div className="text">
                        <h5 className="contact-person-name">Kapilbhai Joshi</h5>
                        <a href="tel:+919723436768">+91 97234 36768</a>
                      </div>
                    </div>
					 <div className="add-list call d-flex align-items-center">
                      <div className="icon">
                        <i className="fi fi-rr-mobile-button contact-icons"></i>
                      </div>
                      <div className="text">
                        <h5 className="contact-person-name">Akshay Dalsaniya ( For Export ) </h5>
                        <a href="tel:+919638919830">+91 96389 19830</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact-form-wrap">
                  <div className="default-form">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      id="contact-us-form"
                    >
                      <div className="form-title">
                        <h1>Contact Us</h1>
                        <div className="content">
                          <p>
                            Please feel free to Contact for any Query or
                            Information.
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label htmlFor="name">
                              Name<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Name"
                              {...register("name", { required: true , setValueAs: value => value.trim() })}
                             
                            />
                            {errors.name && (
                              <span className="text-danger">
                                Please Enter Name.
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group iti mb-0">
                            <label htmlFor="mobile_number">
                              Mobile No<span className="text-danger">*</span>
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              placeholder="Mobile No."
                              minLength="8"
                              maxLength="15"
                              value={inputMobileValue}
                              name="mobile_number"
                              {...register("mobile_number", {
                                required: "Please Enter Mobile No.", setValueAs: value => value.trim()
                              })}
                              onChange={handleMobileInputChange}
                            />
                          </div>
                          {errors.mobile_number && (
                            <span className="text-danger">
                              {errors.mobile_number?.message}
                            </span>
                          )}
                        </div>
                        <div className="col-sm-12 mt-2">
                          <div className="form-group">
                            <label htmlFor="email">
                              Email<span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Email"
                              {...register("email", {
                                required: "Please Enter Email.",
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: "Please Enter Valid Email.",
                                },
                              })}
                            />
                            {errors.email && (
                              <p className="mb-0 text-danger">
                                {errors.email?.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label htmlFor="text">Comments</label>
                            <textarea
                              className="form-textarea"
                              name="comment"
                              defaultValue={""}
                              placeholder="Comments"
                              {...register("comment", { required: false })}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 d-none">
                          <div className="form-group">
                            <label htmlFor="webname">Webname</label>
                            <input
                              type="text"
                              name="webname"
                              className="form-control"
                              id="webname"
                              placeholder="Webname"
                              {...register("webname", { required: false })}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group text-right mb-0">
                            <button
                              type="submit"
                              name="submit"
                              className="primary-btn send-btn"
                            	  disabled={isLoading}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-map">
          <div className="map-wrap">
            <iframe
              width="100%"
              className="iframe-contact"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.303249908402!2d70.87815577444022!3d22.791224624923636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39598df599f5648f%3A0x5f6eb78d125b1706!2sPAVAN%20DIMENSION!5e0!3m2!1sen!2sin!4v1697541818070!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
      </>
    </Layout>
  );
}
