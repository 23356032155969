import React, { useEffect, useState, useRef } from "react";
import Layout from "./layout.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick.js";
import "slick-carousel/slick/slick-theme.css";
import "@flaticon/flaticon-uicons/css/all/all.css";
import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel/dist/owl.carousel.js";
import CountUp from "react-countup";
import bannerImage1 from "../images/main-slider-img.png";
import aboutImage1 from "../images/about-img-1.png";
import aboutImage2 from "../images/image12.png";
import product1 from "../images/product1.png";
import product2 from "../images/product2.png";
import product3 from "../images/product3.png";
import product4 from "../images/product5.png";
import product5 from "../images/product8.png";
import product6 from "../images/product7.png";
import client1 from "../images/clients/c1.png";
import client2 from "../images/clients/c2.png";
import client3 from "../images/clients/c3.png";
import client4 from "../images/clients/c4.png";
import client5 from "../images/clients/c5.png";
import client6 from "../images/clients/c6.png";
import client7 from "../images/clients/c7.png";
import client8 from "../images/clients/c8.png";
import client9 from "../images/clients/c9.png";
import client10 from "../images/clients/c10.png";
import client11 from "../images/clients/c11.png";
import client12 from "../images/clients/c12.png";
import videoLink from "../images/video.mp4";
import videoThumb from "../images/video-thumb.png";
import { seo } from "../seo.js";

import $ from "jquery";
import { Link } from "react-router-dom";
import * as Constants from "../constants";

export default function Home({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    seo({
      title: "Home",
    });
  }, []);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  const [mainnav1, mainsetNav1] = useState();
  const [mainnav2, mainsetNav2] = useState();
  const mainslider1 = useRef(null);
  const mainslider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
    mainsetNav1(mainslider1.current);
    mainsetNav2(mainslider2.current);
  }, []);

  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayIconVisible, setIsPlayIconVisible] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    videoRef.current?.addEventListener("play", () => {
      setIsPlaying(true);
      setIsPlayIconVisible(false);
    });

    videoRef.current?.addEventListener("pause", () => {
      setIsPlaying(false);
      setIsPlayIconVisible(true);
    });

    return () => {
      videoRef.current?.removeEventListener("play", () => {
        setIsPlaying(true);
        setIsPlayIconVisible(false);
      });

      videoRef.current?.removeEventListener("pause", () => {
        setIsPlaying(false);
        setIsPlayIconVisible(true);
      });
    };
  }, [videoRef]);

  const playPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const productSlider = {
    dots: false,
    ref: sliderRef,
    focusOnSelect: false,
    pauseOnHover: false,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2500,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const clientSlider = {
    dots: false,
    focusOnSelect: false,
    pauseOnHover: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    loop: true,
    speed: 500,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const clientSliderDots = {
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    dots: false,
    speed: 500,
    autoplaySpeed: 3000,
    focusOnSelect: true,
    autoplay: true,
    arrows: true,
    loop: true,
    centerMode: true,
  };

  return (
    <Layout>
      <section className="main-slider-section">
        <div className="container-fluid px-0">
          <ReactOwlCarousel
            className="owl-theme main-slider position-relative"
            loop
            margin={0}
            nav
            items={1}
            autoplay={true}
            autoplaySpeed={500}
            smartSpeed={500}
            navSpeed={500}
            fluidSpeed={500}
          >
            <div className="slider-content">
              <div className="slider-img-div">
                <img src={bannerImage1} alt="Banner Image" className="img-fluid slider-img"></img>
              </div>
              <div className="container position-relative h-100">
                <div className="row h-100">
                  <div className="col-lg-12 d-flex align-items-center">
                    <div className="content">
                      <p className="sub-text">All kind of</p>
                      <h3 className="heading">
                        Ceramic Tiles Die <br />{" "}
                        <span className="primary-text">&</span> Mould Solution
                      </h3>
                      <div className="btn-sec">
                        <Link
                          to={Constants.ABOUT_PAGE_URL}
                          className="primary-btn"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-content">
              <div className="slider-img-div">
                <img src={bannerImage1} alt="Banner Image" className="img-fluid slider-img"></img>
              </div>
              <div className="container position-relative h-100">
                <div className="row h-100">
                  <div className="col-lg-12 d-flex align-items-center">
                    <div className="content">
                      <p className="sub-text">All kind of</p>
                      <h3 className="heading">
                        Ceramic Tiles Die <br />{" "}
                        <span className="primary-text">&</span> Mould Solution
                      </h3>
                      <div className="btn-sec">
                        <Link
                          to={Constants.ABOUT_PAGE_URL}
                          className="primary-btn"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-content">
              <div className="slider-img-div">
                <img src={bannerImage1} alt="Banner Image" className="img-fluid slider-img"></img>
              </div>
              <div className="container position-relative h-100">
                <div className="row h-100">
                  <div className="col-lg-12 d-flex align-items-center">
                    <div className="content">
                      <p className="sub-text">All kind of</p>
                      <h3 className="heading">
                        Ceramic Tiles Die <br />{" "}
                        <span className="primary-text">&</span> Mould Solution
                      </h3>
                      <div className="btn-sec">
                        <Link
                          to={Constants.ABOUT_PAGE_URL}
                          className="primary-btn"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactOwlCarousel>
        </div>
      </section>

      <section className="about-section section2">
        <div className="container">
          <div className="row about-section-row">
            <div className="col-lg-6 img-col">
              <div className="main-img-div">
                <h2 className="rotate-left-text">SINCE 2016</h2>
                <div className="two-img-div">
                  <div className="img-div">
                    <img
                      src={aboutImage1}
                      alt="About Image"
                      className="img-fluid about-img img1"
                    />
                  </div>
                  <div className="img-div">
                    <img
                      src={aboutImage2}
                      alt="About Image"
                      className="img-fluid about-img img2"
                    />
                  </div>
                </div>
                <div className="contact-div">
                  <h4 className="text">
                    <span>
                      <CountUp end={8} duration={3} />
                    </span>
                    +
                  </h4>
                  <div className="txt-icon-div">
                    <p className="years-text">Years of Experience</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-content-col d-flex align-items-center">
              <div className="text-content">
                <div className="common-heading">
                  <h1 className="line-heading">
                    <span className="line"></span> About Company
                  </h1>
                  <h4 className="heading">Pavan Dimension</h4>
                </div>
                <p className="text">
                  PAVAN DIMENSION has at its service equipment for designing and
                  producing structured Dies And Punches, for small and large
                  size tiles. We are equipped with lasers for the detection of
                  three-dimensional surfaces, CNC milling machines and presses.
                  Our exclusive L Power system allows us to create structures up
                  to 800 X 1600 MM, with an accuracy in Details And A Production
                  rate until yesterday unobtainable.
                </p>
                <p className="text">
                  Our department in Charge of Numerical Control (CNC) Engraving
                  is able to meet the great demand for this special product
                  intended for the industrial production of tiles. Hundreds of
                  samples in aluminium (CNC) or produced through L Power are
                  displayed in our show-room dedicated to the three-dimensional
                  structures.
                </p>
                <Link to={Constants.ABOUT_PAGE_URL} className="primary-btn">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="product-slider-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-sec">
                <div className="common-heading">
                  <h4 className="line-heading">
                    <span className="line"></span> Products
                  </h4>
                </div>
                <Link to={Constants.PRODUCTS_PAGE_URL} className="view-all-btn">
                  View All{" "}
                  <i className="fi fi-rr-arrow-small-up right-arrow"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="text-content">
                <h4 className="heading">Wide Range of Products</h4>
                <p className="text">
                  We have set up a vast and modern infrastructural facility that
                  is furnished with all required technology and machines.
                </p>
                <p className="text">
                  Products are manufactured using the finest grade basic
                  material and contemporary machines.
                </p>
                <div className="sliders-arrow">
                  <button type="button" className="prev-btn" onClick={previous}>
                    <i className="fi fi-rr-angle-left d-flex arrows"></i>
                  </button>
                  <button type="button" className="next-btn" onClick={next}>
                    <i className="fi fi-rr-angle-right d-flex arrows"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-8 product-slider-col">
              <Slider {...productSlider} className="product-slider row">
                <Link
                  to={Constants.LOWER_SURFACE_PUNCH_PAGE_URL}
                  className="slider-content col-lg-4"
                >
                  <div className="headings">
                    <h4 className="number">1.</h4>
                    <h5 className="title">Lower surface Punch</h5>
                  </div>
                  <div className="img-div">
                    <img
                      src={product1}
                      alt="Lower surface Punch"
                      className="img-fluid slider-img"
                    />
                  </div>
                </Link>
                <Link
                  to={Constants.RUBBER_PUNCH_PAGE_URL}
                  className="slider-content col-lg-4"
                >
                  <div className="headings">
                    <h4 className="number">2.</h4>
                    <h5 className="title">Rubber Punch</h5>
                  </div>
                  <div className="img-div">
                    <img
                      src={product2}
                      alt="Rubber Punch"
                      className="img-fluid slider-img"
                    />
                  </div>
                </Link>
                <Link
                  to={Constants.ISOSTATIC_PUNCH_PAGE_URL}
                  className="slider-content col-lg-4"
                >
                  <div className="headings">
                    <h4 className="number">3.</h4>
                    <h5 className="title">Isostatic Punch</h5>
                  </div>
                  <div className="img-div">
                    <img
                      src={product3}
                      alt="Isostatic Punch"
                      className="img-fluid slider-img"
                    />
                  </div>
                </Link>
                <Link
                  to={Constants.TILES_MOULDS_PAGE_URL}
                  className="slider-content col-lg-4"
                >
                  <div className="headings">
                    <h4 className="number">4.</h4>
                    <h5 className="title">Tiles Moulds</h5>
                  </div>
                  <div className="img-div">
                    <img
                      src={product4}
                      alt="Tiles Moulds"
                      className="img-fluid slider-img"
                    />
                  </div>
                </Link>
                <Link
                  to={Constants.TILES_MATRIX_PAGE_URL}
                  className="slider-content col-lg-4"
                >
                  <div className="headings">
                    <h4 className="number">5.</h4>
                    <h5 className="title">Tiles Matrix</h5>
                  </div>
                  <div className="img-div">
                    <img
                      src={product5}
                      alt="Tiles Matrix (MOTHER MOULD)"
                      className="img-fluid slider-img"
                    />
                  </div>
                </Link>
                <Link
                  to={Constants.CARBIDE_LINERS_PAGE_URL}
                  className="slider-content col-lg-4"
                >
                  <div className="headings">
                    <h4 className="number">6.</h4>
                    <h5 className="title">Carbide Liners</h5>
                  </div>
                  <div className="img-div">
                    <img
                      src={product6}
                      alt="Carbide Liners"
                      className="img-fluid slider-img"
                    />
                  </div>
                </Link>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us-sec position-relative">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-6 col-12">
              <div className="inner-text-content">
                <div className="common-heading">
                  <h4 className="line-heading">
                    <span className="line"></span> Pavan Dimension
                  </h4>
                  <h5 className="heading">Why Choose Us</h5>
                </div>
                <p className="text">
                  We have core expertise in manufacturing Best in Class Ceramic
                  Rubber Punch And Moulds for domestic market as well exporting
                  worldwide. With 8 years of experience and constant R&D, we
                  have developed fast paced and advance technology machines as below.
                </p>
                <div className="listing-div">
                  <p className="list">
                    <i className="fi fi-rr-check-circle check-circle"></i>
                    High Speed Single sided/Double surface Grinding Machine
                  </p>
                  <p className="list">
                    <i className="fi fi-rr-check-circle check-circle"></i>
                    Vertical Milling Centers (VMCs)
                  </p>
                  <p className="list">
                    <i className="fi fi-rr-check-circle check-circle"></i>
                    Computer Numericle Control (CNC)
                  </p>
                  <p className="list">
                    <i className="fi fi-rr-check-circle check-circle"></i>
                    PLC controlled Welding Robot
                  </p>
                  <p className="list">
                    <i className="fi fi-rr-check-circle check-circle"></i>
                    Manual Milling Machines
                  </p>
                  <p className="list">
                    <i className="fi fi-rr-check-circle check-circle"></i>
                    Plano miller & Manual/Radial Drilling Machines
                  </p>
                  <p className="list">
                    <i className="fi fi-rr-check-circle check-circle"></i>
                    Automatic Hydraulic Operated Press
                  </p>
                  <p className="list">
                    <i className="fi fi-rr-check-circle check-circle"></i>
                    Blasting Machine & EOT Crains
                  </p>
                  <p className="list">
                    <i className="fi fi-rr-check-circle check-circle"></i>
                    Punches & Dies And more.
                  </p>
                </div>
                <Link to={Constants.PRODUCTS_PAGE_URL} className="primary-btn">
                  View All Products
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="video-cotnent">
          <video
            className="video-tag"
            id="video-tag"
            poster={videoThumb}
            ref={videoRef}
          >
            <source src={videoLink} type="video/mp4" />
            <source src={videoLink} type="video/ogg" />
          </video>
          {isPlayIconVisible ? (
            <button onClick={playPause} className="playicon-btn">
              <i className="fi fi-rr-play-circle playicon"></i>
            </button>
          ) : (
            <button onClick={playPause} className="playicon-btn pauseicon-btn">
              <i className="fi fi-rr-pause-circle pauseicon playicon"></i>
            </button>
          )}
        </div>
      </section>
      <section className="our-clients-sec">
        <div className="container">
          <div className="heading-sec">
            <div className="common-heading">
              <h4 className="line-heading">
                <span className="line"></span> Valuable
              </h4>
              <h5 className="heading">Our Clients</h5>
            </div>
            <Link to={Constants.CLIENTS_PAGE_URL} className="view-all-btn">
              View All <i className="fi fi-rr-arrow-small-up right-arrow"></i>
            </Link>
          </div>
          <Slider
            {...clientSlider}
            className="row clientSlider"
            ref={slider1}
            asNavFor={nav2}
          >
            <div className="col-lg-3 col-md-3 col-sm-6 client-card-col">
              <div className="client-card">
                <img src={client1} alt="Client Image" className="img-fluid client-img"></img>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 client-card-col">
              <div className="client-card">
                <img src={client2} alt="Client Image" className="img-fluid client-img"></img>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 client-card-col">
              <div className="client-card">
                <img src={client3} alt="Client Image" className="img-fluid client-img"></img>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 client-card-col">
              <div className="client-card">
                <img src={client4} alt="Client Image" className="img-fluid client-img"></img>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 client-card-col">
              <div className="client-card">
                <img src={client5} alt="Client Image" className="img-fluid client-img"></img>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 client-card-col">
              <div className="client-card">
                <img src={client6} alt="Client Image" className="img-fluid client-img"></img>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 client-card-col">
              <div className="client-card">
                <img src={client7} alt="Client Image" className="img-fluid client-img"></img>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 client-card-col">
              <div className="client-card">
                <img src={client8} alt="Client Image" className="img-fluid client-img"></img>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 client-card-col">
              <div className="client-card">
                <img src={client9} alt="Client Image" className="img-fluid client-img"></img>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 client-card-col">
              <div className="client-card">
                <img src={client10} alt="Client Image" className="img-fluid client-img"></img>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 client-card-col">
              <div className="client-card">
                <img src={client11} alt="Client Image" className="img-fluid client-img"></img>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 client-card-col">
              <div className="client-card">
                <img src={client12} alt="Client Image" className="img-fluid client-img"></img>
              </div>
            </div>
          </Slider>
          <Slider
            {...clientSliderDots}
            asNavFor={nav1}
            ref={slider2}
            className="slider-nav slider-item-nav slider_dots slider_dots_clients"
          >
            <div className="items-nav">
              <div className="item-slick"></div>
            </div>
            <div className="items-nav">
              <div className="item-slick"></div>
            </div>
            <div className="items-nav">
              <div className="item-slick"></div>
            </div>
            <div className="items-nav">
              <div className="item-slick"></div>
            </div>
            <div className="items-nav">
              <div className="item-slick"></div>
            </div>
          </Slider>
        </div>
      </section>
    </Layout>
  );
}
