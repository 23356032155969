export const HOME_PAGE_URL = "/";
export const INDEX_PAGE_URL = "/index.html";
export const LANDING_PAGE_URL = "/pavan-dimension.html";
export const ABOUT_PAGE_URL = "/about.html";
export const PRODUCTS_PAGE_URL = "/products.html";
export const LOWER_SURFACE_PUNCH_PAGE_URL = "/lower-surface-punch.html";
export const RUBBER_PUNCH_PAGE_URL = "/rubber-punch.html";
export const ISOSTATIC_PUNCH_PAGE_URL = "/isostatic-punch.html";
export const TILES_MOULDS_PAGE_URL = "/tiles-moulds.html";
export const TILES_MATRIX_PAGE_URL = "/tiles-matrix-mother-mould.html";
export const CARBIDE_LINERS_PAGE_URL = "/carbide-liners.html";
export const CLIENTS_PAGE_URL = "/clients.html";
export const INFRASTRUCTURE_PAGE_URL = "/infrastructure.html";
export const GROUP_OF_COMPANIES_PAGE_URL = "/group-of-companies.html";
export const CONTACT_PAGE_URL = "/contact-us.html";
export const ERROR_404_PAGE_URL = "/404-error";
export const BACKEND_API_URL = "https://www.pavandimension.com/backend/";