import React, { useEffect } from "react";
import Layout from "./layout.js";
import productImage1 from "../images/product1.png";
import productImage2 from "../images/product2.png";
import productImage3 from "../images/product3.png";
import productImage4 from "../images/product5.png";
import productImage5 from "../images/product8.png";
import productImage6 from "../images/product7.png";
import { Link } from "react-router-dom";
import * as Constants from "../constants";
import { seo } from "../seo.js";

export default function Products({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    seo({
      title: "Products",
    });
  }, []);

  return (
    <Layout>
      <div className="common-pageheading">
        <div className="container">
          <h1 className="heading">Products</h1>
        </div>
      </div>

      <section className="products-section">
        <div className="container">
          <div className="our-service-content">
            <div className="row products-row">
              <div className="col-lg-6">
                <Link
                  to={Constants.LOWER_SURFACE_PUNCH_PAGE_URL}
                  className="service-box"
                >
                  <div className="img-div">
                    <img
                      src={productImage1}
                      alt="Lower surface Punch"
                      className="img-fluid our-service-img"
                    />
                  </div>
                  <div className="content">
                    <h4 className="heading">Lower surface Punch</h4>
                    <p className="sub-heading">
                      Punch is important part of mould set. Ceramic tile is
                      formed from punches. There are many types of punches
                      according to Application. These type punch are a
                      combination of Resin and metal with Mirror Finished
                      polished Surface.
                    </p>
                    <div className="read-more-btn">
                      <i className="fi fi-rr-plus-small read-more-icon" />{" "}
                      <span className="read-more-text">View Details</span>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-6">
                <Link
                  to={Constants.RUBBER_PUNCH_PAGE_URL}
                  className="service-box"
                >
                  <div className="img-div">
                    <img
                      src={productImage2}
                      alt="Rubber Punch"
                      className="img-fluid our-service-img"
                    />
                  </div>
                  <div className="content">
                    <h4 className="heading">Rubber Punch</h4>
                    <p className="sub-heading">
                      It is a punch produced in steel, with high resistance
                      hard-facing on the outer edge. It is coated on the surface
                      with rubber or resin which can be of different type and
                      hardness according to the customer’s needs. The goal of
                      this product is to obtain superficial creations with the
                      desired effects.
                    </p>
                    <div
                      className="read-more-btn"
                    >
                      <i className="fi fi-rr-plus-small read-more-icon" />{" "}
                      <span className="read-more-text">View Details</span>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-6">
                <Link
                  to={Constants.ISOSTATIC_PUNCH_PAGE_URL}
                  className="service-box"
                >
                  <div className="img-div">
                    <img
                      src={productImage3}
                      alt="Isostatic Punch"
                      className="img-fluid our-service-img"
                    />
                  </div>
                  <div className="content">
                    <h4 className="heading">Isostatic Punch</h4>
                    <p className="sub-heading">
                      This type of isostatic punch allow to obtain high
                      reliability, ensuring compensation on large surface tiles.
                    </p>
                    <div
                      className="read-more-btn"
                    >
                      <i className="fi fi-rr-plus-small read-more-icon" />{" "}
                      <span className="read-more-text">View Details</span>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-6">
                <Link
                  to={Constants.TILES_MOULDS_PAGE_URL}
                  className="service-box"
                >
                  <div className="img-div">
                    <img
                      src={productImage4}
                      alt="Tiles Moulds"
                      className="img-fluid our-service-img"
                    />
                  </div>
                  <div className="content">
                    <h4 className="heading">Tiles Moulds</h4>
                    <p className="sub-heading">
                      The Entry Type Mould stands out as the favoured option due
                      to its exceptional simplicity, versatility, and
                      cost-effectiveness in construction. It seamlessly works
                      with presses of all types and effortlessly accommodates
                      various tile bodies, such as double-fired, single-fired,
                      and porcelain stoneware. When inserting the tile, it is
                      important to position it upside down, ensuring that the
                      desired side faces downwards
                    </p>
                    <div
                      className="read-more-btn"
                    >
                      <i className="fi fi-rr-plus-small read-more-icon" />{" "}
                      <span className="read-more-text">View Details</span>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-lg-6">
                <Link
                  to={Constants.TILES_MATRIX_PAGE_URL}
                  className="service-box"
                >
                  <div className="img-div">
                    <img
                      src={productImage5}
                      alt="Tiles Matrix (MOTHER MOULD)"
                      className="img-fluid our-service-img"
                    />
                  </div>
                  <div className="content">
                    <h4 className="heading">Tiles Matrix (MOTHER MOULD)</h4>
                    <p className="sub-heading">
                      Our Engineers and Designers team up to creating Tile Metal
                      Dies that have excellent functional properties. Our
                      designs match changing customer’s needs and upgrades in
                      technology to deliver high return on investments.
                      Contemporary styles, surface effects of 3 Dimensions, and
                      long life are just some of the outcomes of our cutting
                      edge expertise in the department.
                    </p>
                    <div
                      className="read-more-btn"
                    >
                      <i className="fi fi-rr-plus-small read-more-icon" />{" "}
                      <span className="read-more-text">View Details</span>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-lg-6">
                <Link
                  to={Constants.CARBIDE_LINERS_PAGE_URL}
                  className="service-box"
                >
                  <div className="img-div">
                    <img
                      src={productImage6}
                      alt="Carbide Liners"
                      className="img-fluid our-service-img"
                    />
                  </div>
                  <div className="content">
                    <h4 className="heading">Carbide Liners</h4>
                    <p className="sub-heading">
                      Liners are always made of alloy that is hard and strong.
                      So that it can be used for pressing for more than million
                      times.
                    </p>
                    <div
                      className="read-more-btn"
                    >
                      <i className="fi fi-rr-plus-small read-more-icon" />{" "}
                      <span className="read-more-text">View Details</span>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-lg-6">
                <table className="technical-specification-table">
                  <tbody>
                    <tr>
                      <th className="main-heading" colSpan={2}>
                        Technical Specification
                      </th>
                    </tr>
                    <tr>
                      <td
                        className="table-data"
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #CFD9E0",
                        }}
                      >
                        Surface Hardness
                      </td>
                      <td className="table-data" style={{ width: "50%" }}>
                        96+
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="table-data"
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #CFD9E0",
                        }}
                      >
                        Measurement Variation
                      </td>
                      <td className="table-data" style={{ width: "50%" }}>
                        (+/-)0.005MM
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="table-data"
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #CFD9E0",
                        }}
                      >
                        Welding Hardness
                      </td>
                      <td className="table-data" style={{ width: "50%" }}>
                        95 HRC
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="table-data"
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #CFD9E0",
                        }}
                      >
                        Stock Capacity
                      </td>
                      <td className="table-data" style={{ width: "50%" }}>
                        6 Lacs+
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="table-data"
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #CFD9E0",
                        }}
                      >
                        Packing
                      </td>
                      <td className="table-data" style={{ width: "50%" }}>
                        Pvc|Wooden Box
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
