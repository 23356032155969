import React, { useEffect, useState, useRef } from "react";
import Layout from "./layout.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick.js";
import "slick-carousel/slick/slick-theme.css";
import productImage1 from "../images/product1.png";
import productImageLarge1 from "../images/lower-surface-punch-l.png";
import productImage2 from "../images/product2.png";
import productImage3 from "../images/product3.png";
import productImage4 from "../images/product5.png";
import productImage5 from "../images/product8.png";
import productImage6 from "../images/product7.png";
import { Link } from "react-router-dom";
import * as Constants from "../constants";
import { seo } from "../seo.js";

export default function LowerSurfacePunch({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    seo({
      title: "Lower Surface Punch",
    });
  }, []);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const productSlider = {
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    focusOnSelect: false,
    pauseOnHover: false,
    unslick: true
  };

  const otherProductSlider = {
    dots: false,
    focusOnSelect: false,
    pauseOnHover: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const otherProductSliderDots = {
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    dots: false,
    autoplaySpeed: 3000,
    speed: 500,
    focusOnSelect: true,
    autoplay: true,
    arrows: true,
    loop: true,
    centerMode: true,
  };

  return (
    <Layout>
      <div className="common-pageheading">
        <div className="container">
          <h1 className="heading">Lower surface Punch</h1>
        </div>
      </div>

      <section className="product-page-details">
        <div className="container">
          <div className="row other-product-slider-row">
            <div className="col-lg-6">
              <Slider {...productSlider} className="productimg-slider-div">
                <img
                  src={productImageLarge1}
                  alt="Lower surface Punch"
                  className="img-fluid product-img"
                ></img>
              </Slider>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <div className="product-detail">
                <h4 className="product-name">Lower Surface Punch</h4>
                <p className="product-txt">
                  Punch is important part of mould set. Ceramic tile is formed
                  from punches. There are many types of punches according to
                  Application. These type punch are a combination of Resin and
                  metal with Mirror Finished polished Surface.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="other-product-slider products-section ">
        <div className="container">
          <div className="title-heading">
            <h4 className="heading">Other Products</h4>
          </div>
          <Slider
            {...otherProductSlider}
            className="row our-service-content"
            ref={slider1}
            asNavFor={nav2}
          >
            <div className="col-lg-6">
              <div className="service-box">
                <div className="img-div">
                  <img
                    src={productImage2}
                    alt="Rubber Punch"
                    className="img-fluid our-service-img"
                  />
                </div>
                <div className="content">
                  <Link to={Constants.RUBBER_PUNCH_PAGE_URL} className="heading">Rubber Punch</Link>
                  <p className="sub-heading">
                    It is a punch produced in steel, with high resistance
                    hard-facing on the outer edge. It is coated on the surface
                    with rubber or resin which can be of different type and
                    hardness according to the customer’s needs. The goal of this
                    product is to obtain superficial creations with the desired
                    effects.
                  </p>
                  <Link
                    to={Constants.RUBBER_PUNCH_PAGE_URL}
                    className="read-more-btn"
                  >
                    <i className="fi fi-rr-plus-small read-more-icon" />{" "}
                    <span className="read-more-text">View Details</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="service-box">
                <div className="img-div">
                  <img
                    src={productImage3}
                    alt="Isostatic Punch"
                    className="img-fluid our-service-img"
                  />
                </div>
                <div className="content">
                  <Link to={Constants.ISOSTATIC_PUNCH_PAGE_URL} className="heading">Isostatic Punch</Link>
                  <p className="sub-heading">
                    This type of isostatic punch allow to obtain high
                    reliability, ensuring compensation on large surface tiles.
                  </p>
                  <Link
                    to={Constants.ISOSTATIC_PUNCH_PAGE_URL}
                    className="read-more-btn"
                  >
                    <i className="fi fi-rr-plus-small read-more-icon" />{" "}
                    <span className="read-more-text">View Details</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="service-box">
                <div className="img-div">
                  <img
                    src={productImage4}
                    alt="Tiles Moulds"
                    className="img-fluid our-service-img"
                  />
                </div>
                <div className="content">
                  <Link to={Constants.TILES_MOULDS_PAGE_URL} className="heading">Tiles Moulds</Link>
                  <p className="sub-heading">
                    The Entry Type Mould stands out as the favoured option due
                    to its exceptional simplicity, versatility, and
                    cost-effectiveness in construction. It seamlessly works with
                    presses of all types and effortlessly accommodates various
                    tile bodies, such as double-fired, single-fired, and
                    porcelain stoneware. When inserting the tile, it is
                    important to position it upside down, ensuring that the
                    desired side faces downwards
                  </p>
                  <Link
                    to={Constants.TILES_MOULDS_PAGE_URL}
                    className="read-more-btn"
                  >
                    <i className="fi fi-rr-plus-small read-more-icon" />{" "}
                    <span className="read-more-text">View Details</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="service-box">
                <div className="img-div">
                  <img
                    src={productImage5}
                    alt="Tiles Matrix (MOTHER MOULD)"
                    className="img-fluid our-service-img"
                  />
                </div>
                <div className="content">
                  <Link to={Constants.TILES_MATRIX_PAGE_URL} className="heading">Tiles Matrix (MOTHER MOULD)</Link>
                  <p className="sub-heading">
                    Our Engineers and Designers team up to creating Tile Metal
                    Dies that have excellent functional properties. Our designs
                    match changing customer’s needs and upgrades in technology
                    to deliver high return on investments. Contemporary styles,
                    surface effects of 3 Dimensions, and long life are just some
                    of the outcomes of our cutting edge expertise in the
                    department.
                  </p>
                  <Link
                    to={Constants.TILES_MATRIX_PAGE_URL}
                    className="read-more-btn"
                  >
                    <i className="fi fi-rr-plus-small read-more-icon" />{" "}
                    <span className="read-more-text">View Details</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="service-box">
                <div className="img-div">
                  <img
                    src={productImage6}
                    alt="Carbide Liners"
                    className="img-fluid our-service-img"
                  />
                </div>
                <div className="content">
                  <Link to={Constants.CARBIDE_LINERS_PAGE_URL} className="heading">Carbide Liners</Link>
                  <p className="sub-heading">
                    Liners are always made of alloy that is hard and strong. So
                    that it can be used for pressing for more than million
                    times.
                  </p>
                  <Link
                    to={Constants.CARBIDE_LINERS_PAGE_URL}
                    className="read-more-btn"
                  >
                    <i className="fi fi-rr-plus-small read-more-icon" />{" "}
                    <span className="read-more-text">View Details</span>
                  </Link>
                </div>
              </div>
            </div>
          </Slider>
          <Slider
            {...otherProductSliderDots}
            asNavFor={nav1}
            ref={slider2}
            className="slider-nav slider-item-nav slider_dots slider_dots_clients"
          >
            <div className="items-nav">
              <div className="item-slick"></div>
            </div>
            <div className="items-nav">
              <div className="item-slick"></div>
            </div>
            <div className="items-nav">
              <div className="item-slick"></div>
            </div>
            <div className="items-nav">
              <div className="item-slick"></div>
            </div>
            <div className="items-nav">
              <div className="item-slick"></div>
            </div>
          </Slider>
        </div>
      </section>
    </Layout>
  );
}
