import React, { useEffect } from "react";
import Layout from "./layout.js";
import valenciaceramicpvtltd from "../images/group-of-companies/valencia-ceramic-pvt-ltd.png";
import vellozagranitollp from "../images/group-of-companies/velloza-granito-llp.png";
import velzonegranitollp from "../images/group-of-companies/velzone-granito-llp.png";
import fonic from "../images/group-of-companies/fonic.png";
import capcochemindustries from "../images/group-of-companies/capco-chem-industries.png";
import lithoschemindustries from "../images/group-of-companies/lithos-chem-industries.png";
import pavansutminerals from "../images/group-of-companies/pavansut-minerals.png";
import scopetiles from "../images/group-of-companies/scope-tiles.png";
import { seo } from "../seo.js";

export default function Clients({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    seo({
      title: "Group of Companies",
    });
  }, []);

  return (
    <Layout>
      <div className="common-pageheading">
        <div className="container">
          <h1 className="heading">Group of Companies</h1>
        </div>
      </div>

      <section className="group-of-company">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
                <img src={valenciaceramicpvtltd} alt="Valencia Ceramic PVT LTD" className="img-fluid company-group-img" />
            </div>
            <div className="col-lg-9 d-flex flex-column justify-content-center">
                <h2 className="fw-bold mb-2"> <span className="text-theme-color">Valencia Ceramic PVT LTD</span></h2>
                <h5 className="product-content fw-bold mb-2">Product Name : <span className="text-light-product-content fw-normal">Wall Tiles (300*600 & 300*450)</span></h5>
                <h5 className="product-content fw-bold mb-2">Type of Business : <span className="text-light-product-content fw-normal"> Manufacturer & Supplier</span></h5>
                <h5 className="product-content fw-bold mb-2">Domestic/International : <span className="text-light-product-content fw-normal">Export into More than 85+ Countries </span></h5>
                <h5 className="product-content fw-bold mb-2">Company Address : <span className="text-light-product-content fw-normal text-capitalize">Jetpar Road , Near Bella Village, Morbi - 363642 Gujarat India</span></h5>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3">
                <img src={valenciaceramicpvtltd} alt="Valenza Ceramic LLP" className="img-fluid company-group-img" />
            </div>
            <div className="col-lg-9 d-flex flex-column justify-content-center">
                <h2 className="fw-bold mb-2"> <span className="text-theme-color">Valenza Ceramic LLP</span></h2>
                <h5 className="product-content fw-bold mb-2">Product Name : <span class="text-light-product-content fw-normal"><strong class="text-dark"> Floor Tiles -</strong> 300*300 & 600*600 <strong class="text-dark"> Vitrified Tiles -</strong> 800x2400 & 800x3000 & 1200x2400 & 1000x1000 & 1200x1800 Sanitary Wares All Type Of Ceramic Product.</span></h5>
                <h5 className="product-content fw-bold mb-2">Type of Business : <span className="text-light-product-content fw-normal"> Manufacturer & Supplier</span></h5>
                <h5 className="product-content fw-bold mb-2">Domestic/International : <span className="text-light-product-content fw-normal">Domestic & International Level</span></h5>
                <h5 className="product-content fw-bold mb-2">Company Address : <span className="text-light-product-content fw-normal text-capitalize">Jetpar Road , Near Bella Village, Morbi - 363642 Gujarat India</span></h5>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3">
                <img src={vellozagranitollp} alt="Velloza Granito LLP" className="img-fluid company-group-img" />
            </div>
            <div className="col-lg-9 d-flex flex-column justify-content-center">
                <h2 className="fw-bold mb-2"> <span className="text-theme-color">Velloza Granito LLP</span></h2>
                <h5 className="product-content fw-bold mb-2">Product Name : <span class="text-light-product-content fw-normal">Gvt & Pgvt - 600x1200 ( Vitrified / Porcelain Tiles )</span></h5>
                <h5 className="product-content fw-bold mb-2">Type of Business : <span className="text-light-product-content fw-normal"> Manufacturer & Supplier</span></h5>
                <h5 className="product-content fw-bold mb-2">Domestic/International : <span className="text-light-product-content fw-normal">Export into More than 95+ Countries</span></h5>
                <h5 className="product-content fw-bold mb-2">Company Address : <span className="text-light-product-content fw-normal text-capitalize">Near Khokhara Hanuman Temple, Bella - Bharat Nagar Road,Morbi -363641, Gujarat India</span></h5>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3">
                <img src={velzonegranitollp} alt="Velzone Granito LLP" className="img-fluid company-group-img" />
            </div>
            <div className="col-lg-9 d-flex flex-column justify-content-center">
                <h2 className="fw-bold mb-2"> <span className="text-theme-color">Velzone Granito LLP</span></h2>
                <h5 className="product-content fw-bold mb-2">Product Name : <span class="text-light-product-content fw-normal">Gvt & Pgvt - 800x1600 & 800x800</span></h5>
                <h5 className="product-content fw-bold mb-2">Type of Business : <span className="text-light-product-content fw-normal"> Manufacturer & Supplier</span></h5>
                <h5 className="product-content fw-bold mb-2">Domestic/International : <span className="text-light-product-content fw-normal">Export into More than 66+ Countries</span></h5>
                <h5 className="product-content fw-bold mb-2">Company Address : <span className="text-light-product-content fw-normal text-capitalize">Near Khokhara Hanuman Temple, Bella - Bharat Nagar Road,Morbi -363641, Gujarat India</span></h5>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3">
                <img src={fonic} alt="Fonic Colour" className="img-fluid company-group-img" />
            </div>
            <div className="col-lg-9 d-flex flex-column justify-content-center">
                <h2 className="fw-bold mb-2"> <span className="text-theme-color">Fonic Colour</span></h2>
                <h5 className="product-content fw-bold mb-2">Product Name : <span class="text-light-product-content fw-normal">Body Colour, Glaze Colour, Ceramic Rubber Punch, Master Mould, Soluble Salt, STPP</span></h5>
                <h5 className="product-content fw-bold mb-2">Type of Business : <span className="text-light-product-content fw-normal"> Manufacturer & Exporter</span></h5>
                <h5 className="product-content fw-bold mb-2">Domestic/International : <span className="text-light-product-content fw-normal">Domestic & International Level</span></h5>
                <h5 className="product-content fw-bold mb-2">Company Address : <span className="text-light-product-content fw-normal text-capitalize">Survey No. 67/3 Paiki 3, Nr. Heena Petrol Pump, Rajkot-Kandla Highway, Morbi Bypass Road, Dharampur, Morbi 363 642. Gujarat ( India )</span></h5>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3">
                <img src={capcochemindustries} alt="Capco Chem Industries" className="img-fluid company-group-img" />
            </div>
            <div className="col-lg-9 d-flex flex-column justify-content-center">
                <h2 className="fw-bold mb-2"> <span className="text-theme-color">Capco Chem Industries</span></h2>
                <h5 className="product-content fw-bold mb-2">Product Name : <span class="text-light-product-content fw-normal">Liquid Soluble Salt Colour</span></h5>
                <h5 className="product-content fw-bold mb-2">Type of Business : <span className="text-light-product-content fw-normal"> Manufacturer & Supplier</span></h5>
                <h5 className="product-content fw-bold mb-2">Domestic/International : <span className="text-light-product-content fw-normal">Domestic & International Level</span></h5>
                <h5 className="product-content fw-bold mb-2">Company Address : <span className="text-light-product-content fw-normal text-capitalize">Rafaleshwar GIDC , Plot No 249 8-A , National Highway Morbi 363642 Gujarat India</span></h5>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3">
                <img src={lithoschemindustries} alt="Lithos Chem Industries" className="img-fluid company-group-img" />
            </div>
            <div className="col-lg-9 d-flex flex-column justify-content-center">
                <h2 className="fw-bold mb-2"> <span className="text-theme-color">Lithos Chem Industries</span></h2>
                <h5 className="product-content fw-bold mb-2">Product Name : <span class="text-light-product-content fw-normal">Liquid STPP</span></h5>
                <h5 className="product-content fw-bold mb-2">Type of Business : <span className="text-light-product-content fw-normal"> Manufacturer & Supplier</span></h5>
                <h5 className="product-content fw-bold mb-2">Domestic/International : <span className="text-light-product-content fw-normal">Domestic & International Level</span></h5>
                <h5 className="product-content fw-bold mb-2">Company Address : <span className="text-light-product-content fw-normal text-capitalize">56/57 Rafaleshwar Gidc , 8-A National Highway, Morbi 363 642, Guj., India.</span></h5>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3">
                <img src={pavansutminerals} alt="Pavansut Minerals" className="img-fluid company-group-img" />
            </div>
            <div className="col-lg-9 d-flex flex-column justify-content-center">
                <h2 className="fw-bold mb-2"> <span className="text-theme-color">Pavansut Minerals</span></h2>
                <h5 className="product-content fw-bold mb-2">Product Name : <span class="text-light-product-content fw-normal">Ceramic Minerals</span></h5>
                <h5 className="product-content fw-bold mb-2">Type of Business : <span className="text-light-product-content fw-normal"> Manufacturer & Supplier</span></h5>
                <h5 className="product-content fw-bold mb-2">Domestic/International : <span className="text-light-product-content fw-normal">only domestic</span></h5>
                <h5 className="product-content fw-bold mb-2">Company Address : <span className="text-light-product-content fw-normal text-capitalize">Jetpar Road, Near Bela Village, B/H Valencia Ceramic, Morbi - 363 642, Guj., India.</span></h5>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3">
                <img src={scopetiles} alt="Scope Tiles" className="img-fluid company-group-img" />
            </div>
            <div className="col-lg-9 d-flex flex-column justify-content-center">
                <h2 className="fw-bold mb-2"> <span className="text-theme-color">Scope Tiles</span></h2>
                <h5 className="product-content fw-bold mb-2">Product Name : <span class="text-light-product-content fw-normal">Vitrified Tiles, Floor Tiles, Wall Tiles, Sanitary Ware</span></h5>
                <h5 className="product-content fw-bold mb-2">Type of Business : <span className="text-light-product-content fw-normal"> Manufacturer & Supplier</span></h5>
                <h5 className="product-content fw-bold mb-2">Domestic/International : <span className="text-light-product-content fw-normal">only domestic</span></h5>
                <h5 className="product-content fw-bold mb-2">Company Address : <span className="text-light-product-content fw-normal text-capitalize">Office No. 317, Bilva Shopping Near Mahendra Nagar Circle, Morbi - 363 642, Guj., India.</span></h5>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3">
                <img src={valenciaceramicpvtltd} alt="Valenza Granito LLP" className="img-fluid company-group-img" />
            </div>
            <div className="col-lg-9 d-flex flex-column justify-content-center">
                <h2 className="fw-bold mb-2"> <span className="text-theme-color">Valenza Granito LLP</span></h2>
                <h5 className="product-content fw-bold mb-2">Product Name : <span class="text-light-product-content fw-normal">Gvt & Pgvt - 600x1200 ( Vitrified / Porcelain Tiles )</span></h5>
                <h5 className="product-content fw-bold mb-2">Type of Business : <span className="text-light-product-content fw-normal"> Manufacturer & Supplier</span></h5>
                <h5 className="product-content fw-bold mb-2">Domestic/International : <span className="text-light-product-content fw-normal">Export into More than 85+ Countries </span></h5>
                <h5 className="product-content fw-bold mb-2">Company Address : <span className="text-light-product-content fw-normal text-capitalize">Jetpar Road , Near Rangpar Village, Morbi - 363642 Gujarat India</span></h5>
            </div>
          </div>



        </div>
      </section>

    </Layout>
  );
}
