import $ from 'jquery';

//designers
function menuDrop() {
	if ($(window).innerWidth() > 991) {
		$(
			".twt-navbar .nav-item.dropdown, .twt-navbar .dropdown-submenu"
		).hover(
			function() {
				$(this)
					.find(".dropdown-menu")
					.first()
					.stop(true, true)
					.delay(250)
					.slideDown(150);
			},
			function() {
				$(this)
					.find(".dropdown-menu")
					.first()
					.stop(true, true)
					.delay(100)
					.slideUp(100);
			}
		);
		$(".twt-navbar .dropdown > a").click(function() {
			window.location.href = this.href;
		});
	}
}

$(document).ready(function() {
	menuDrop();
	// $("#slide-toggle").on("click", function() {
	// 	$("body").toggleClass("nav-slide-open");
	// });

	
$(function() {
		// var current = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
		var current = window.location.href;

		if (current != "") {
			$(".nav-link").each(function() {
				var href = $(this).attr("href");
				if (href == current) {
					var check_header_class = $(this).parents('body').hasClass('vertical-header');
					if( check_header_class != false ){
						$(this).parent().addClass("active");
						$(this).parents('.sub-dropdown-collapse').addClass('show');
						$(this).parents('.items-drodown-menu').addClass('show');
						$(this).parents('.dropdown-sub-megamenu').addClass('active');
						

						$(this).parents('.nav-items-class').addClass('active');	
					}
				}
			});
			$(".nav-link").each(function() {
				var href = $(this).attr("href");
				if (href == current) {
					$(this)
						.parent()
						.addClass("active");
				}
			});
		}
	});

	$(document).on("click", function(e) {
		// console.log(!$(e.target).is('#slide-toggle, #slide-toggle .fas'), $(window).innerWidth() < 992);
		if (
			$(window).innerWidth() < 992 &&
			!$(e.target).is("#slide-toggle, #slide-toggle .fas")
		) {
			$("body").removeClass("nav-slide-open");
		}
	});

	// sidebar - admin side
	$(document).on("click", ".navbar-toggler", function() {
		$("#wrapper").toggleClass("toggled");
	});
	// sidebar sub menu
	$('.sidebar [data-bs-toggle="collapse"]').on("click", function() {
		var current = $(this);
		current
			.parent()
			.siblings()
			.find(".collapse.show")
			.collapse("hide");
	});



	if (window.location.hash) {

		setTimeout(function() {
			window.scrollTo(0, 0);
		}, 1);
		setTimeout(function() {
			$("html, body").animate(
				{
					scrollTop: $(window.location.hash).offset().top - 96
				},
				1000
			);
		}, 300);
	}

	$(document).on('click' , 'a[href*="#"]' , function(event){
		var hash = this.hash;
		if (hash !== "" && $(hash).length) {
			event.preventDefault();

			if (!$(this).attr("data-bs-toggle")) {
				$("html, body").animate(
					{
						scrollTop:
							$(hash).offset().top -
							$(".navbar").outerHeight() -
							70
					},
					800
				);
			}
		}
	});
	
	var topOffset = $("#navMain").attr("data-offset")
		? parseInt($("#navMain").attr("data-offset"))
		: 0;
	if ($(".main-navbar-wrapper").hasClass("fallen-nav")) {
		$(".main-navbar-wrapper").css(
			"min-height",
			$("#navMain").outerHeight() + topOffset
		);
	} else if ($(".main-navbar-wrapper").hasClass("notch-nav")) {
		$(".main-navbar-wrapper").css(
			"min-height",
			$("#navMain").outerHeight() + topOffset
		);
	} else {
		$(".main-navbar-wrapper").css(
			"min-height",
			$("#navMain").outerHeight()
		);
	}

	setTimeout(function()  {
	}, 300);

	if ($(document).find(".select2").length > 0) {
		$(".select2").select2();
	}

	// slick slider
	$("#header-slider").on("init", function(e, slick) {
		var $firstAnimatingElements = $("div.header-slide:first-child").find(
			"[data-animation]"
		);
		doAnimations($firstAnimatingElements);
	});
	$("#header-slider").on("beforeChange", function(
		e,
		slick,
		currentSlide,
		nextSlide
	) {
		var $animatingElements = $(
			'div.header-slide[data-slick-index="' + nextSlide + '"]'
		).find("[data-animation]");
		doAnimations($animatingElements);
	});
	if ($("#header-slider").length > 0) {
		$("#header-slider").slick({
			autoplay: true,
			autoplaySpeed: 4000,
			dots: false,
			arrows: true,
			// fade: true,
			pauseOnHover: false
		});
	}

	function doAnimations(elm) {
		var animationEndEvents =
			"webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend";
		elm.each(function() {
			var $this = $(this);
			var $animationDelay = $this.data("delay");
			var $animationType = "animated " + $this.data("animation");
			$this.css({
				"animation-delay": $animationDelay,
				"-webkit-animation-delay": $animationDelay
			});
			$this.addClass($animationType).one(animationEndEvents, function() {
				$this.removeClass($animationType);
			});
		});
	}
	// slick slider end

	/* $(document).on('change' , 'input[type="file"]' , function(e){
		let filenames = [];

		let files = e.target.files;

		if (files.length > 1) {
			// filenames.push(files.length + " images added");
			filenames.push("{{ trans('messages.multiple-file-added') }}");
		} else {
			for (let i in files) {
				if (files.hasOwnProperty(i)) {
					filenames.push(files[i].name);
				}
			}
		}
		$(this).siblings(".custom-file-label").html(filenames.join(","));
	}) */

	$(".dropdown > a, .dropdown-submenu > a").on("click", function(e) {

		if ($(window).innerWidth() < 992) {
			e.preventDefault();
		}

		var submenu = $(this);
		$(this)
			.parent()
			.siblings()
			.find(".dropdown-menu")
			.removeClass("show");
		// submenu.next(".dropdown-menu").toggleClass("show");
		e.stopPropagation();
	});

	// hide any open menus when parent closes
	$(".dropdown").on("hidden.bs.dropdown", function() {
		$(".dropdown-menu.show").parent().removeClass("show");
	});
});
$(window).resize(function() {
	setTimeout(function() {
		menuDrop();
	}, 500);
});

$(window).scroll(function() {
	if ($(this).scrollTop() > 72) {
		$(".twt-navbar").addClass("fixed");
	} else {
		$(".twt-navbar").removeClass("fixed");
	}
});


function getFileBaseName(str)
{
	var base = '';
	if( str != "" && str != null ){
	  var base = new String(str).substring(str.lastIndexOf('/') + 1); 
	    if(base.lastIndexOf(".") != -1)       
	        base = base.substring(0, base.lastIndexOf("."));
		
	}
 
   return base;
}

function checkAll(thisitem){
	$(thisitem).closest('table').find('.all-checkbox').prop('checked' , $(thisitem).prop('checked'));
}

function thousands_separators(num)
{
  var num_parts = num.toString().split(".");
  
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if(num_parts[1] != "" && num_parts[1] != null ){
	  num_parts[1] = parseInt(num_parts[1]);
	  num_parts[1] = num_parts[1].toFixed(2);
  }
  return num_parts.join(".");
}

function formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    
  }
};

function enumText(str){
	var str = str.replace(/_/g, ' ');
	str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
	    return letter.toUpperCase();
	});
	return str;
}

function onlyNumberWithSpace(thisitem) {
	var $val = $(thisitem)
		.val()
		.replace(/[^ \d]/g, "");
	$(thisitem).val($val);
}

$(document).ready(function () {  

var bootstrapModalCounter = 0;
	$('.modal').on("hidden.bs.modal", function (e) {
	--bootstrapModalCounter;
	
	if (bootstrapModalCounter > 0) {
			$('body').addClass('modal-open');
	}
	}).on("show.bs.modal", function (e) {
		++bootstrapModalCounter;
		$(document).off('focusin.modal');
		const zIndex = 1050 + 10 * $('.modal:visible').length; 
		$(this).css('z-index', zIndex); 
		setTimeout(() => $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack')); 
	});

	$('.select2').each(function () {
      $(this).select2({
          theme: 'bootstrap-5',
          dropdownParent: $(this).parent(),
      });
  });
});
