import React, { useEffect } from "react";
import Layout from "./layout.js";
import client1 from "../images/clients/c1.png";
import client2 from "../images/clients/c2.png";
import client3 from "../images/clients/c3.png";
import client4 from "../images/clients/c4.png";
import client5 from "../images/clients/c5.png";
import client6 from "../images/clients/c6.png";
import client7 from "../images/clients/c7.png";
import client8 from "../images/clients/c8.png";
import client9 from "../images/clients/c9.png";
import client10 from "../images/clients/c10.png";
import client11 from "../images/clients/c11.png";
import client12 from "../images/clients/c12.png";
import client13 from "../images/clients/c13.png";
import client14 from "../images/clients/c14.png";
import client15 from "../images/clients/c15.png";
import client16 from "../images/clients/c16.png";
import client17 from "../images/clients/c17.png";
import client18 from "../images/clients/c18.png";
import client19 from "../images/clients/c19.png";
import client20 from "../images/clients/c20.png";
import client21 from "../images/clients/c21.png";
import client22 from "../images/clients/c22.png";
import client23 from "../images/clients/c23.png";
import client24 from "../images/clients/c24.png";
import client25 from "../images/clients/c25.png";
import client26 from "../images/clients/c26.png";
import client27 from "../images/clients/c27.png";
import client28 from "../images/clients/c28.png";
import client29 from "../images/clients/c29.png";
import client30 from "../images/clients/c30.png";
import client31 from "../images/clients/c31.png";
import client32 from "../images/clients/c32.png";
import client33 from "../images/clients/c33.png";
import client34 from "../images/clients/c34.png";
import client35 from "../images/clients/c35.png";
import client36 from "../images/clients/c36.png";
import client37 from "../images/clients/c37.png";
import client38 from "../images/clients/c38.png";
import client39 from "../images/clients/c39.png";
import client40 from "../images/clients/c40.png";
import client41 from "../images/clients/c41.png";
import client42 from "../images/clients/c42.png";
import { seo } from "../seo.js";

export default function Clients({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    seo({
      title: "Clients",
    });
  }, []);

  return (
    <Layout>
      <div className="common-pageheading">
        <div className="container">
          <h1 className="heading">Clients</h1>
        </div>
      </div>

      <section className="client-page-section">
        <div className="container">
          <div className="row client-page-sec-row">
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client1}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client2}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client3}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client4}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client5}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client6}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client7}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client8}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client9}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client10}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client11}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client12}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client13}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client14}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client15}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client16}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client17}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client18}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client19}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client20}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client21}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client22}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client23}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client24}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client25}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client26}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client27}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client28}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client29}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client30}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client31}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client32}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client33}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client34}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client35}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client36}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client37}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client38}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client39}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client40}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client41}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client42}
                  className="img-fluid client-img"
                  alt="Client Image"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
